import { cdnUrl } from '@cucumber/ui/stores/ui.store'
import { get } from 'svelte/store'

export function uuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
    })
}

export function ucFirst(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getDirectusAssetLink = (assetLocation: Maybe<string>): string => {
    let cdn = get(cdnUrl)

    if (!assetLocation || !cdn) return '/assets/images/media-fall-back-image.png'

    if (['http://', 'https://'].some(p => assetLocation.startsWith(p))) return assetLocation

    return `${cdn}/${assetLocation}`
}
